import Script from 'next/script';

const ZohoMarketing = () => {
  return (
    <Script id="zsiqmarketing" type="text/javascript">
      {zohoMarketingScript}
    </Script>
  );
};

export default ZohoMarketing;

const zohoMarketingScript = `
var w=window;var p = w.location.protocol;if(p.indexOf("http") < 0){p = "http"+":";}var d = document;var f = d.getElementsByTagName('script')[0],s = d.createElement('script');s.type = 'text/javascript'; s.async = false; if (s.readyState){s.onreadystatechange = function(){if (s.readyState=="loaded"||s.readyState == "complete"){s.onreadystatechange = null;try{loadwaprops("3z356dc97e42220519fd6ac54841e1332c","3zb834cde643f36664476dafa7d03377b2","3z32ae19ddddce16360f9ba2f9e30aff7b14aaf3eabb8a1e835b3dc3e27ff7a956","3zc0537b2c09c3e25d4f8f2d61ba2a8f47","0.0");}catch(e){}}};}else {s.onload = function(){try{loadwaprops("3z356dc97e42220519fd6ac54841e1332c","3zb834cde643f36664476dafa7d03377b2","3z32ae19ddddce16360f9ba2f9e30aff7b14aaf3eabb8a1e835b3dc3e27ff7a956","3zc0537b2c09c3e25d4f8f2d61ba2a8f47","0.0");}catch(e){}};};s.src =p+"//ma.zoho.com/hub/js/WebsiteAutomation.js";f.parentNode.insertBefore(s, f);
`;
